<style lang="less" scoped>
  @import "../../../assets/css/variables";

  #main {
    width: 80%;
    margin: 0 auto;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>联网状态报表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <div>
        <el-form :inline="true">
          <el-form-item v-if="isAdmin">
            <el-select
              v-model="deviceId"
              placeholder="请选择产品型号"
              style="width:100%;"
            >
              <el-option
                v-for="item in dvcTypeList"
                :key="item.id"
                :label="item.deviceTypeCode + item.productTypeCode"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else>
            <el-select
              v-model="deviceId"
              placeholder="请选择产品型号"
              style="width:100%;"
            >
              <el-option
                v-for="item in brandTypeList"
                :key="item.id"
                :label="item.deviceTypeCode + item.productTypeCode"
                :value="item.deviceType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              :picker-options="pickerOption1"
              placeholder="选择日期范围"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div v-show="chartShow" id="main"></div>
      <!-- <div v-else>暂无信息</div> -->
    </div>
  </div>
</template>

<script>
  import {
    getProductAuthoData,
    getSaleData,
    getAlertCountData,
    getOnlineData,
  } from "../../../services/report";
  import { queryBrander } from "../../../services/customer";
  import { mapGetters } from "vuex";
  import echarts from "echarts";
  import moment from "moment";
  import { Notification } from "element-ui";
  import {
    searchProByCustomer,
    searchDevicesByType,
  } from "../../../services/device";
  export default {
    data() {
      return {
        pager: {
          size: 9999,
          page: 1,
        },
        isAdmin: false,
        dateRange: [],
        pickerOption1: {
          disabledDate(date) {
            return date.getTime() > new Date().getTime();
          },
        },
        customer: "",
        deviceId: "",
        brandList: [],
        dvcTypeList: [],
        year: "",
        yearList: [],
        tableData: [],
        month: 12,
        monthShow: 12,
        brandTypeList: [],
        type: "every",
        timeLine: [],
        valueList: [],
        maxValue: 100,
        chartShow: true,
        chartOptions: {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          visualMap: {
            type: "piecewise",
            splitNumber: 5,
            min: 0,
            max: 100,
            left: 20,
            bottom: "center",
            calculable: true,
            color: ["#d94e5d", "#eac736", "#32CD32"],
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: ["a", "b", "c", "d", "e"],
            },
          ],
          yAxis: [
            {
              type: "value",
              //splitNumber: 5,
              minInterval: 1,
              nameLocation: "end",
            },
          ],
          series: [
            {
              type: "line",
              smooth: true,
              showSymbol: false,
              areaStyle: { normal: {} },
              // data: this.streamDataPoint
              data: [10, 20, 30, 40, 50],
            },
          ],
        },
      };
    },
    created() {
      this.checkRole();
      this.initDate();
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      //角色判断
      checkRole() {
        const role = this.user.role;
        if (/admin/.test(role)) {
          this.isAdmin = true;
          this.getDvcList();
        } else {
          this.customer = this.user.customerId;
          this.queryBrandTypeList();
        }
      },
      // 初始化日期
      initDate() {
        let today = new Date();
        this.dateRange = [
          new Date(today.getTime() - 3600 * 24 * 6 * 1000),
          today,
        ];
      },
      /**
       * 获取产品型号列表
       */
      async getDvcList() {
        const responseData = await searchDevicesByType(
          {},
          { page: 0, size: 999 }
        );
        if (responseData.errorCode === 0) {
          this.dvcTypeList =
            (responseData && responseData.data && responseData.data.datas) ||
            [];
        }
      },
      /**
       * 获取品牌型号列表
       */
      async queryBrandTypeList() {
        const params = { customer: this.customer };
        const responseData = await searchProByCustomer(params, this.pager);
        if (responseData.errorCode === 0) {
          this.brandTypeList =
            (responseData && responseData.data && responseData.data.datas) ||
            [];
          if (this.brandTypeList.length > 0) {
            this.deviceId = this.brandTypeList[0].deviceType;
          }
        }
      },
      // brandChange() {
      //   this.deviceId = null;
      //   this.queryBrandTypeList();
      // },

      //联网状态报表
      async getData() {
        if (!this.deviceId || this.dateRange.length === 0) {
          return;
        }
        let params = {
          fromTime: this.dateRange[0].getTime(),
          toTime: this.dateRange[1].getTime(),
          deviceType: this.deviceId,
          //customer: this.customer,
        };
        let res = await getOnlineData(params);
        this.timeLine = [];
        this.valueList = [];
        if (res && res.errorCode === 0 && res.data) {
          if (res.data.length === 0) {
            this.chartShow = false;
            Notification.warning({
              title: "提示",
              message: "暂无数据",
            });
            return;
          } else {
            this.chartShow = true;
          }
          res.data.forEach((item) => {
            this.timeLine.push(moment(item.createTime).format("YYYY-MM-DD"));
            this.valueList.push(item.total);
          });
          this.getMax(this.valueList);
          this.showChart();
        }
      },
      showChart() {
        //console.log(this.timeLine)
        let main = document.getElementById("main");
        this.chartOptions.xAxis[0].data = this.timeLine;
        this.chartOptions.series[0].data = this.valueList;
        this.chartOptions.visualMap.max = this.maxValue;
        this.chartOptions.yAxis[0].max = this.maxValue;
        this.chartOptions.visualMap.text = [this.maxValue, "0"];
        this.chartOptions.yAxis[0].name = "联网 （台）";
        this.chartOptions.xAxis[0].name = "日期";
        main.style.height = window.innerHeight - 130 + "px";
        main.style.width = window.innerWidth - 350 + "px";
        this.myChart = echarts.init(main);
        this.myChart.setOption(this.chartOptions, true);
      },

      // 获取最大值；
      getMax(arr) {
        var temp = Object.assign([], arr);
        temp.sort(function(a, b) {
          return b - a;
        });
        this.maxValue = Math.ceil(temp[0] * 1.5);
      },
    },
  };
</script>
